<template>
  <v-sheet :set="hover = false"
           class="wsRoundedLight pointer"
           @dragover.prevent="dropzoneHover=true"
           @dragleave.prevent="dropzoneHover=false"
           @drop.prevent="drop"
           style="transition: all 0.3s ease; position: relative"
           :style="!src ? `border : 2px dashed ${wsACCENT}` : ''"
           :color="!src ? wsLIGHTCARD : 'transparent' "
           min-height="200px"
           @click="selectFile">

    <input style="display: none" id="videoInput" type="file"  @change="fileBrowsed">

    <v-sheet :color="dropzoneHover ? 'rgba(47,47,47,0.15)' : '#00000000' "
             style="position:absolute; width: 100%; height: 100% !important; transition: all 0.3s ease"
             class=" d-flex align-center justify-center wsRoundedLight ">



      <div class="text-center " v-if="!src">
        <v-fade-transition mode="out-in">
          <div v-if="!uploading">
            <v-icon :color="wsACCENT" x-large>mdi-tray-arrow-down</v-icon>
            <h5 :style="`color : ${wsACCENT}`" v-if="!customText" >Оберіть файл або перетягніть сюди</h5>
            <slot v-else></slot>
          </div>
          <div v-if="uploading" >
            <v-progress-circular color="white" indeterminate  />
            <h3 style="color : white" class="text-center font-weight-light">{{ progres }} / 100 </h3>
          </div>

        </v-fade-transition>
      </div>

    </v-sheet>
    <v-expand-transition>

      <v-img ratio="1" :src="src" transition="xxx" />

    </v-expand-transition>

  </v-sheet>
</template>

<script>

// import axios from 'axios';
import {mapActions, mapState} from "vuex";

export default {
  name: "wsImageUpload",
  props : {
    src : {
      type : String,
      default : ''
    },
    type : {
      type : String,
      default : ''
    },
    customText : {
      type : Boolean,
      default : false,
    },
    id : {
      type : String,
      default : ''
    },
    noId : {
      type : Boolean,
      default : false
    },
    allTypes : {
      type : Boolean,
      default : false
    },
    mimeGroup : {
      type : String,
      default : 'video'
    },
    superadmin : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      dropzoneHover : false,
      signedUrl : '',
      uploading : false,
      progres : 0,
      file : null,
      fullPath : '',
      fileName : '',

    }
  },
  computed : {
    ...mapState( 'dashboard', [  'UPLOAD_LOADING'  ]),
  },
  watch : {
    id() {
      if (this.id && this.file) {
        this.upload()
      }
    }
  },
  methods : {
    ...mapActions('upload', [ 'UPLOAD_GET_SIGNED_URL', 'UPLOAD_GET_SIGNED_URL_SUPERADMIN']),

    progress(uploadProgress,) {
      if ( uploadProgress.loaded !== 1) {
        this.uploading = true

        this.progres =  Math.round((uploadProgress.loaded/uploadProgress.total)*100)
      } else {
        this.uploading = false
      }

    },

    selectFile() {
      document.getElementById('videoInput').click()
    },

    async upload() {

      if (!this.file.type.includes(this.mimeGroup) && !this.allTypes) {
        return this.notify(this.$t('WrongVideoFileUploadAttempt'))
      }

      let data = {
        name : this.file.name,
        type : this.file.type
      }

      let file =  this.file

      let result = !this.superadmin ?  await this.UPLOAD_GET_SIGNED_URL(data) : await this.UPLOAD_GET_SIGNED_URL_SUPERADMIN(data)
      if ( !result ) { return this.notify('Error') }
      let signed_url = result.url
      this.fileName = result.name
      this.fullPath = result.full_path

      // let stream = file.stream().getReader()

      let xhr = new XMLHttpRequest();
      xhr.open('PUT', signed_url);
      xhr.onload = () => {
        this.uploading = false
        let data = {
          uuid : this.id,
          name : this.fileName,
          full_path : this.fullPath,
          mime : this.file.type
        }
        this.$emit('success',data)
      };
      xhr.onerror =  () => { console.log('error') }
      xhr.upload.onprogress = this.progress
      xhr.setRequestHeader('Content-Type', file.type);
      xhr.send(file);
    },
    async fileBrowsed(e) {
      this.$emit('upload',e.target.files[0])
      this.file = e.target.files[0]
      if ( this.id || this.noId ) {
        await this.upload()
      }
    },
    async drop(e) {
      this.$emit('upload',e.dataTransfer.files[0])
      this.file = e.dataTransfer.files[0]
      if ( this.id || this.noId ) {
        await this.upload()
      }

    },
  }
}
</script>

<style scoped>

</style>