<template>
  <ft-select v-if="!autocomplete" v-model="manager" :items="items">
    <template v-slot:default="{text}">
      <h5>{{ text ? text : $t('Manager')  }}</h5>
    </template>
  </ft-select>
  <v-autocomplete :placeholder="placeholder" :label="label" outlined hide-details dense v-else v-model="manager" :items="items" />
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "wsCrmAdminManagersSelect",
  props: {
    value : {
      type : String,
      default : ''
    },
    placeholder : {
      type : String,
      default : ''
    },
    label : {
      type : String,
      default : ''
    },
    autocomplete : {
      type : Boolean,
      default : false
    },
    business : {
      type : Boolean,
      default : false,
    }
  },
  data() {
    return {
      items : [],
      manager : null,
    }
  },
  watch:  {
    manager(value) {
      if (value !== this.value) {
        this.$emit('input',value)
      }
    },
    value(value) {
      if (value !== this.manager) {
        this.manager = value
      }
    }
  },
  methods : {
    ...mapActions('adminCrmSystem', [
      'ADMIN_CRM_GET_MANAGERS_SELECT',
    ]),
    ...mapActions('crmSystem', [
      'BUSINESS_CRM_GET_MANAGERS_SELECT',
    ]),
  },
  async mounted() {

    if ( this.value ) {
      this.manager = this.value
    }

    let result = this.business ? await this.BUSINESS_CRM_GET_MANAGERS_SELECT() : await this.ADMIN_CRM_GET_MANAGERS_SELECT()
    if (!result || result === true ) { return }
    this.items = result

  }
}
</script>

<style scoped>

</style>