<template>
  <sub-page
      v-model="$store.state.dashboard.pageData"
      :title="$t('AllEvents')"
      :subtitle="$t('Tasks')"
      :root="$t('Dashboard')"
      icon="mdi-check"
      :side-navigation="navigationItems"
      navigation-route="tasks"
  >


    <template #default>

      <ft-select @input="openAddEvent($event)" :items="eventTypesChange">
        <v-btn dark class="noCaps" :color="wsACCENT" >
          <v-icon small class="mr-1">mdi-plus</v-icon>
          {{ $t('AddEvent') }}
        </v-btn>
      </ft-select>


      <v-sheet :color="wsACCENT" dark class="d-flex justify-space-between align-center wsRoundedHalf mt-6 py-3 px-9 ">
        <div class="d-flex align-center pr-16 mr-6 fill-height">
          <h5 class="mr-6 d-flex align-center"> <v-icon class="mr-1" small >mdi-tune-vertical</v-icon> Filters: </h5>
          <v-sheet  class="mr-3 "  color="transparent" width="200">
            <v-chip  class=" font-weight-medium" small outlined>
              <v-icon small>mdi-magnify</v-icon>
              <v-text-field v-model="search" style="font-size: 12px " :placeholder="$t('Search')" height="10" flat solo hide-details background-color="transparent" />
            </v-chip>
          </v-sheet>

          <ft-select v-model="$store.state.adminCrmSystem.eventsFilter.type"   :items="eventTypes" >
            <template v-slot:default="{text}">
              <v-chip  class="mr-3 font-weight-medium" small outlined>
                {{ text ? text : $t('Type') }}
              </v-chip>
            </template>
          </ft-select>
          <ft-select v-model="$store.state.adminCrmSystem.eventsFilter.is_completed"  :items="CHOICE_COMPLETED_SELECT" >
            <v-chip  class="mr-3 font-weight-medium" small outlined>
              <span v-if="$store.state.adminCrmSystem.eventsFilter.is_completed"> {{ $t('Completed')  }} </span>
              <span v-else-if="$store.state.adminCrmSystem.eventsFilter.is_completed === false"> {{ $t('NotCompleted')  }} </span>
              <span v-else> {{ $t('Completion')  }} </span>
            </v-chip>
          </ft-select>
          <ft-select v-model="$store.state.adminCrmSystem.eventsFilter.event_origin"  :items="taskOriginSelect" >
            <template v-slot:default="{text}">
              <v-chip  class="mr-3 font-weight-medium" small outlined>
                {{ text }}
              </v-chip>
            </template>
          </ft-select>
          <v-btn v-if="clearFiltersCondition" class="my-0"
                 icon small @click="$store.state.adminCrmSystem.eventsFilter = { event_origin : 'clients' }; search = '' "><v-icon small>mdi-close</v-icon> </v-btn>
        </div>

        <wsChip icon="mdi-cog" >
          <h5>{{ $t('Total')}} : {{ itemsFiltered.length }}</h5>
        </wsChip>

      </v-sheet>
      <!-- Events table-->
      <v-sheet class="wsRoundedHalf mt-6 pa-6">
        <v-data-table :items="itemsFiltered" :headers="headers" :search="search" :loading="LOADING">
          <template v-slot:item="{item}">
            <tr @contextmenu="openMenu($event, item)"
                @click="selectedEvent = item; openTask(item)"
                class="pointer">
              <td width="10px">
                <v-avatar size="32" :color="getEventColor(item.type)">
                  <v-icon dark> {{ getEventIcon(item.type) }} </v-icon>
                </v-avatar>
              </td>
              <td class="py-2">
                <h5>{{ item.business_name }} <span v-if="item.business_name">:</span> {{ item.name }}</h5>
                <h5 style="white-space: pre-wrap" class="font-weight-light mt-2" v-html="item.text"></h5>
              </td>
              <td class="text-no-wrap"> <wsChip :color="wsACCENT" icon="mdi-file" :text="item.files"></wsChip> </td>
              <td class="text-no-wrap"> <wsChip :color="wsACCENT" icon="mdi-account-tie" :text="item.owner_name"></wsChip> </td>
              <td @click.stop="" >
                <ft-select  @input="changeEvent(item,'is_completed',$event)" :items="CHOICE_COMPLETED" fillHeight>
                  <wsChip :color="item.is_completed ? wsACCENT : 'grey'"
                          :icon="item.is_completed ? 'mdi-check' : 'mdi-cog'"
                          :text="item.is_completed  ? $t('Completed')  : $t('InProcess')"   no-wrap  >
                    <v-icon small>mdi-chevron-down</v-icon>
                  </wsChip>
                </ft-select>
              </td>
              <td class="text-no-wrap">  <wsChip :color="wsACCENT" icon="mdi-calendar" :text="PARSE_TIME_STAMP(item.date_created , true)"></wsChip> </td>
              <td> <v-btn @click="openTask(item)" :color="wsATTENTION" icon> <v-icon> mdi-chevron-right-circle</v-icon></v-btn> </td>
            </tr>
          </template>
        </v-data-table>
      </v-sheet>
    </template>
    <template #dialog>
      <!-- Add Event Dialog-->
      <wsDialog v-model="displayNewDialog"
                :title="$t('Event')"
                @save="addEvent"
                width="900">

        <div class="d-flex justify-space-between">

          <div class="d-flex align-center ">
            <v-avatar class="mr-3" size="42" :color="getEventColor(entityData.type)">
              <v-icon dark> {{ getEventIcon(entityData.type) }} </v-icon>
            </v-avatar>
            <v-text-field v-model="entityData.name"
                          style="font-size: 0.83em;"
                          row-height="1"
                          hide-details
                          :placeholder="$t('EnterEventName')"
                          single-line
                          auto-grow flat solo   />

          </div>

          <div class="mt-2">
            <div class="d-flex justify-end pointer">
              <ft-select v-model="entityData.is_completed" :items="CHOICE_COMPLETED">
                <v-chip dark  class="ml-3 pointer" :color="selectedEvent.is_completed ? wsSUCCESSDARK : 'grey lighten-1'" >
                  <h5 class="pointer text-no-wrap" >
                    {{ selectedEvent.is_completed  ? $t('Completed')  : $t('InProcess') }}
                  </h5>
                  <v-icon small>mdi-chevron-down</v-icon>
                </v-chip>
              </ft-select>
            </div>
          </div>

        </div>

        <v-sheet class="wsRoundedHalf pa-6 mt-9" outlined >

          <v-row>
            <v-col>
              <h5 class="mb-3">{{$t('Manager')}}</h5>
              <wsCrmAdminManagersSelect  autocomplete  v-model="selectedEvent.manager_id" />
            </v-col>
            <v-col>
              <h5 class="mb-3">{{$t('DateScheduled')}}</h5>
              <ws-date-picker v-model="selectedEvent.date_scheduled" ></ws-date-picker>
            </v-col>
          </v-row>

          <h5 class="mt-6"> {{ $t('Text') }}: </h5>
          <v-textarea v-model="entityData.text"
                      style="font-size: 0.83em;"
                      row-height="1"
                      hide-details
                      :placeholder="$t('EnterText')"
                      single-line
                      auto-grow flat solo   />

          <h5 v-if="entityData.files.length > 0" class="mt-6 "> {{ $t('Files') }}: </h5>
          <div class="mt-6">
            <v-slide-x-transition mode="out-in">
              <v-simple-table v-if="!addFile"  dense>
                <tbody>
                <tr class="pointer" @click="openFile(item)" v-for="(item,j) in entityData.files" :key="'files_' + j">
                  <td style="border-bottom: none" width="10px"><v-icon :color="wsACCENT" small>mdi-file</v-icon></td>
                  <td style="border-bottom: none">{{ item.name }}</td>
                </tr>
                </tbody>
              </v-simple-table>
              <wsVideoUpload v-if="addFile" superadmin no-id all-types @success="fileUploaded"   />
            </v-slide-x-transition>

            <v-btn :color="wsACCENT" @click="addFile = !addFile" block text small class="noCaps mt-3"> {{ !addFile ? $t('AddFile') : $t('Back') }} </v-btn>

          </div>

        </v-sheet>

      </wsDialog>
      <!-- Edit Event Dialog-->
      <wsDialog v-model="displayDialog"
                :title="$t('Event')"
                :show-save="false"
                :cancel-text="$t('Back')"
                width="900">

        <div class="d-flex justify-space-between">

          <div class="d-flex align-center ">
            <v-avatar class="mr-3" size="42" :color="getEventColor(selectedEvent.type)">
              <v-icon dark> {{ getEventIcon(selectedEvent.type) }} </v-icon>
            </v-avatar>
            <div>
              <h3 class="font-weight-medium">{{ selectedEvent.name }}</h3>
              <h4 class="font-weight-light">{{ selectedEvent.business_name }}</h4>
            </div>

          </div>

          <div class="mt-2">
            <div class="d-flex justify-end pointer">
              <ft-select @input="changeEvent(selectedEvent,'is_completed',$event)" :items="CHOICE_COMPLETED">
                <v-chip dark  class="ml-3 pointer" :color="selectedEvent.is_completed ? wsSUCCESSDARK : 'grey lighten-1'" >
                  <h5 class="pointer text-no-wrap" >
                    {{ selectedEvent.is_completed  ? $t('Completed')  : $t('InProcess') }}
                  </h5>
                  <v-icon small>mdi-chevron-down</v-icon>
                </v-chip>
              </ft-select>
            </div>
          </div>

        </div>

        <v-sheet class="wsRoundedHalf pa-6 mt-9" outlined >

          <v-row>
            <v-col>
              <h5 class="mt-1" v-if="selectedEvent.business_name"> {{ $t('Business') }}: <span class="font-weight-light ml-3">{{ selectedEvent.business_name }}</span> </h5>
              <h5 class="mt-1 d-flex align-center "> {{ $t('Type') }}:
                <ft-select v-model="selectedEvent.type"
                           @input="changeEvent(selectedEvent,'type',$event)"
                           :items="eventTypesChange">

                  <template v-slot:default="{text}">
                    <span class="ml-3 font-weight-light">{{ text }}</span>
                    <v-icon small>mdi-chevron-down</v-icon>
                  </template>
                </ft-select>
              </h5>
            </v-col>
            <v-col >
              <div class="d-flex justify-end">
                <div>
                  <wsChip class="text-no-wrap" :color="wsACCENT" icon="mdi-account-tie" :text="selectedEvent.owner_name"></wsChip>
                  <ws-chip  class="mt-1" centered :color="wsACCENT" icon="mdi-calendar" :text="PARSE_TIME_STAMP(selectedEvent.date_created , true)"></ws-chip>
                </div>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <h5 class="mb-3">{{$t('Manager')}}</h5>
              <wsCrmAdminManagersSelect  autocomplete @input="changeEvent(selectedEvent,'manager_id',$event)" v-model="selectedEvent.manager_id" />
            </v-col>
            <v-col>
              <h5 class="mb-3">{{$t('DateScheduled')}}</h5>
              <ws-date-picker @input="changeEvent(selectedEvent,'date_scheduled',$event)" v-model="selectedEvent.date_scheduled" ></ws-date-picker>
            </v-col>
          </v-row>

          <h5 class="mt-6"> {{ $t('Text') }}: </h5>
          <v-textarea v-model="selectedEvent.text"
                      @change="changeEvent(selectedEvent,'text',$event)"
                      style="font-size: 0.83em;"
                      row-height="1"
                      hide-details
                      :placeholder="$t('EnterText')"
                      single-line
                      auto-grow flat solo   />

          <h5 v-if="selectedEvent.files.length > 0" class="mt-6 "> {{ $t('Files') }}: </h5>
          <div class="mt-6">
            <v-slide-x-transition mode="out-in">
              <v-simple-table v-if="!addFile"  dense>
                <tbody>
                <tr class="pointer" @click="openFile(item)" v-for="(item,j) in selectedEvent.files" :key="'files_' + j">
                  <td style="border-bottom: none" width="10px"><v-icon :color="wsACCENT" small>mdi-file</v-icon></td>
                  <td style="border-bottom: none">{{ item.name }}</td>
                </tr>
                </tbody>
              </v-simple-table>
              <wsVideoUpload v-if="addFile" superadmin no-id all-types @success="fileUploaded"   />
            </v-slide-x-transition>

            <v-btn :color="wsACCENT" @click="addFile = !addFile" block text small class="noCaps mt-3"> {{ !addFile ? $t('AddFile') : $t('Back') }} </v-btn>

          </div>

        </v-sheet>

      </wsDialog>
      <ft-select @input="contextAction" :x="x" :y="y" absolute :expanded="displayMenu" :items="eventActions" />
    </template>

  </sub-page>
</template>

<script>
import {mapActions} from "vuex";
import wsVideoUpload from "@/components/UI/videoPlayer/wsVideoUpload";
import wsCrmAdminManagersSelect from "@/components/pages/adminDashboard/UI/wsCrmAdminManagersSelect";

export default {
  name: "Tasks",
  components : {
    wsVideoUpload,
    wsCrmAdminManagersSelect
  },
  data() {
    return {
      addFile : false,
      items : [],
      filter : {},

      search : '',
      displayDialog : false,
      displayNewDialog : false,
      displayMenu : false,
      x : 5,
      Y : 5,
      entityData : {
        files : []
      },
      selectedEvent : {
        files : []
      },
      selectedEventBusinessId : '',
    }
  },
  computed : {

    navigationItems() {
      return [
        { text : this.$t('AllEvents') , path : '' },
        { text : this.$t('Projects')  , path : 'projects' },
        { text : this.$t('Tickets')   , path : 'tickets' },
        { text : this.$t('Bugs')      , path : 'bugs' },
      ]
    },


    taskOriginSelect() {
      return [
        { text : this.$t('All') , value : 'all' },
        { text : this.$t('Clients') , value : 'clients'},
        { text : this.$t('GeneralEvents') , value : 'general' }
      ]
    },

    clearFiltersCondition() {

      return this.$store.state.adminCrmSystem.eventsFilter.type
          || this.$store.state.adminCrmSystem.eventsFilter.is_completed === true
          || this.$store.state.adminCrmSystem.eventsFilter.is_completed === false
          || this.search
    },

    eventTypesChange() {
      return [
        { text : this.$t('Note')      , value : 'note'       },
        { text : this.$t('Call')      , value : 'call'       },
        { text : this.$t('VideoCall') , value : 'video_call' },
        { text : this.$t('Task')      , value : 'task'       },
        { text : this.$t('File')      , value : 'file'       },
        { text : this.$t('Bug')       , value : 'bug'        }
      ]
    },

    headers() {
      let items = [
        { value : 'icon' } ,
        { text : this.$t('Event') , value : 'name' } ,
        { text : this.$t('Files') , value : 'files' } ,
        { text : this.$t('Manager') , value : 'owner_name' } ,
        { text : this.$t('Completion') , value : 'is_completed' } ,
        { text : this.$t('Date') , value : 'date_created' } ,
        { value : 'action' } ,
      ]
      return items
    },
    itemsFiltered() {
      let items = this.items
      if ( this.$store.state.adminCrmSystem.eventsFilter.type ) {
        items = items.filter( el=> el.type === this.$store.state.adminCrmSystem.eventsFilter.type)
      }
      if ( this.$store.state.adminCrmSystem.eventsFilter.is_completed === true ||
          this.$store.state.adminCrmSystem.eventsFilter.is_completed === false
      ) {
        items = items.filter( el=> el.is_completed === this.$store.state.adminCrmSystem.eventsFilter.is_completed)
      }
      if ( this.$store.state.adminCrmSystem.eventsFilter.event_origin ) {
        switch ( this.$store.state.adminCrmSystem.eventsFilter.event_origin ) {
          case 'clients' : items = items.filter(el => el.business_id); break;
          case 'general' : items = items.filter(el => !el.business_id); break;
        }
      }
      return items
    },
    eventTypes() {
      return [
        { text : this.$t('All')       , value : null          },
        { text : this.$t('Note')      , value : 'note'       },
        { text : this.$t('Call')      , value : 'call'       },
        { text : this.$t('VideoCall') , value : 'video_call' },
        { text : this.$t('Task')      , value : 'task'       },
        { text : this.$t('File')      , value : 'file'       },
        { text : this.$t('Bug')       , value : 'bug'       }
      ]
    },
    eventActions() {
      return [
        { text : this.$t('View')         , icon : 'mdi-eye', value : 'viewEvent'          },
        { text : this.$t('BusinessCard') , icon: 'mdi-briefcase-outline',  value : 'gotoCard'   , route : '/dashboard/businesses/' + this.selectedEventBusinessId  },
        { text : this.$t('Completion')   , icon: 'mdi-check', value : 'gotoCard'   ,  children : this.CHOICE_COMPLETED },
        { text : this.$t('Type')         , icon: 'mdi-cog', children : [
            { text : this.$t('Note')      , value : 'change_type_note'       },
            { text : this.$t('Call')      , value : 'change_type_call'       },
            { text : this.$t('VideoCall') , value : 'change_type_video_call' },
            { text : this.$t('Task')      , value : 'change_type_task'       },
            { text : this.$t('File')      , value : 'change_type_file'       },
            { text : this.$t('Bug')      , value : 'change_type_bug'       }
          ]
        },

      ]
    }
  },
  methods : {
    ...mapActions('adminCrmSystem', [
      'ADMIN_CRM_GET_TASKS',
      'ADMIN_CRM_GET_BUSINESS_EVENT',
      'ADMIN_CRM_ADD_BUSINESS_EVENT',
      'ADMIN_CRM_EDIT_BUSINESS_EVENT',
      'GET_SUPERADMIN_FILE'
    ]),

    openAddEvent(type) {

      this.entityData = {
        files : [],
        type : type
      }
      this.displayNewDialog = true
    },

    fileUploaded($event) {
      if ( this.displayNewDialog ) {
        this.entityData.files.push($event)
      } else {
        this.selectedEvent.files.push($event)
      }


      this.addFile = false
      this.notify(this.$t('FileUploaded'))
      this.changeEvent(this.selectedEvent,'new_file',$event)
    },
    contextAction(action) {
      switch(action) {
        case 'viewEvent' : return this.openTask(this.selectedEvent)

        case 'change_type_note'       : return this.changeEvent(this.selectedEvent, 'type' , 'note')
        case 'change_type_call'       : return this.changeEvent(this.selectedEvent, 'type' , 'call')
        case 'change_type_video_call' : return this.changeEvent(this.selectedEvent, 'type' , 'vide_call')
        case 'change_type_task'       : return this.changeEvent(this.selectedEvent, 'type' , 'task')
        case 'change_type_file'       : return this.changeEvent(this.selectedEvent, 'type' , 'file')
        case 'change_type_bug'        : return this.changeEvent(this.selectedEvent, 'type' , 'bug')

        case true : return this.changeEvent(this.selectedEvent,'is_completed', true)
        case false : return this.changeEvent(this.selectedEvent,'is_completed', false)
      }
    },
    openMenu($event , item) {
      this.selectedEventBusinessId = item.business_id
      this.selectedEvent = item
      $event.preventDefault()
      this.displayMenu = false
      this.x = $event.clientX
      this.y = $event.clientY
      this.$nextTick(() => {
        this.displayMenu = true
      })
    },

    async addEvent() {
      let result =  await this.ADMIN_CRM_ADD_BUSINESS_EVENT(this.entityData)
      if  (!result ) { return }
      result.files = result.files.length
      this.items.push(result)
      this.displayNewDialog = false
    },
    async openTask(item) {
      this.selectedEvent = await this.ADMIN_CRM_GET_BUSINESS_EVENT(item.uuid) || []
      this.displayDialog = true
    },
    async changeEvent(item,field,value) {

      let data = {
        uuid : item.uuid,
        [field] : value
      }
      let result = await this.ADMIN_CRM_EDIT_BUSINESS_EVENT(data)
      if (!result ) { return }
      let entity =  this.items.find( el => el.uuid === item.uuid)

      value = field === 'new_file' ? result.files :  value
      field = field === 'new_file' ? 'files' :  field

      item[field] = value
      if (entity) {
        value = field === 'files' ? result.files.length :  value
        entity[field] = value
      }
    },
    async openFile(file) {
      let data =  {
        file : file.uuid,
        course : this.uuid
      }
      let result = await this.GET_SUPERADMIN_FILE(data)
      if (!result) { return this.notify('File Not found') }
      var a = document.createElement('a');
      a.href = result.url;
      if (result.mime.includes('image')) {
        a.target = "_blank";
      }

      a.download = file.name;
      document.body.appendChild(a);
      a.click();
      a.remove();
    },

    getEventIcon(type) {
      switch(type) {
        case 'note' : return 'mdi-notebook';
        case 'call' : return 'mdi-phone';
        case 'video_call' : return 'mdi-video';
        case 'task' : return 'mdi-checkbox-marked-outline';
        case 'file' : return 'mdi-file';
        case 'bug' : return 'mdi-bug';
        case 'registration' : return 'mdi-briefcase-outline'
        case 'status_change' : return 'mdi-cog';
        default : return 'mdi-cog';
      }
    },
    getEventColor(type) {
      switch(type) {
        case 'note' : return 'orange';
        case 'call' : return 'green lighten-1';
        case 'video_call' : return 'blue lighten-1';
        case 'task' : return 'teal lighten-1';
        case 'file' : return 'primary lighten-1';
        case 'bug' : return 'red lighten-2';
        case 'registration' : return 'green lighten-2';
        case 'status_change' : return 'purple lighten-1';
        default : return 'grey';
      }
    },

  },
  async mounted() {
    this.items = await this.ADMIN_CRM_GET_TASKS(this.is_westudy ? 'westudy' : 'fine') || []
  }
}
</script>

<style scoped>

</style>